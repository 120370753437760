/* src/App.css */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: radial-gradient(circle, rgba(0, 0, 20, 1) 0%, rgba(0, 0, 40, 1) 100%);
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: -1;
}

.star {
  width: 1px;
  height: 1px;
  background: white;
  position: absolute;
  border-radius: 50%;
  opacity: 0.8;
  animation: twinkle 5s infinite;
}

@keyframes twinkle {
  0%, 20%, 50%, 70%, 100% {
    opacity: 0.8;
  }
  25% {
    opacity: 0.2;
  }
  60% {
    opacity: 0.4;
  }
}
